import type { TrashTypes } from "helpers/history/type"

export enum LOG_KIND {
    INFO,
    ERROR,
    DEBUG,
}

export interface LogType {
    title: string
    content: string
    type: LOG_KIND
}

export interface State {
    result:
        | null
        | [results: { label: typeof TrashTypes[number]; value: any }[], time: number]
    logs: Array<LogType>
    capture: HTMLCanvasElement | null
    feedbackCapture: HTMLCanvasElement | null
    openFeedback?: () => void | undefined
    doPredict: number
}

export const initalState: State = {
    result: null,
    logs: [],
    capture: null,
    feedbackCapture: null,
    doPredict: 0,
}

export enum MAIN_VIEW_ACTIONS {
    SET_RESULT = "MAIN_VIEW/SET_RESULT",
    ADD_LOG = "MAIN_VIEW/ADD_LOG",
    SET_CAPTURE = "MAIN_VIEW/SET_CAPTURE",
    SET_FEEDBACK_CAPTURE = "MAIN_VIEW/SET_FEEDBACK_CAPTURE",
    SET_FN_FEEDBACK = "MAIN_VIEW/SET_FN_FEEDBACK",
    SET_DO_PREDICT = "MAIN_VIEW/SET_DO_PREDICT",
}

export type Actions =
    | {
          type: MAIN_VIEW_ACTIONS.SET_RESULT
          result: [{ label: typeof TrashTypes[number]; value: any }[], number]
      }
    | {
          type: MAIN_VIEW_ACTIONS.ADD_LOG
          log: LogType
      }
    | {
          type: MAIN_VIEW_ACTIONS.SET_CAPTURE
          capture: HTMLCanvasElement
      }
    | {
          type: MAIN_VIEW_ACTIONS.SET_FEEDBACK_CAPTURE
          capture: HTMLCanvasElement
      }
    | {
          type: MAIN_VIEW_ACTIONS.SET_FN_FEEDBACK
          fn: () => void
      }
    | {
          type: MAIN_VIEW_ACTIONS.SET_DO_PREDICT
          predict: -1 | 0 | 1
      }

export const setResult = (
    result: [{ label: typeof TrashTypes[number]; value: any }[], number],
): Actions => ({
    type: MAIN_VIEW_ACTIONS.SET_RESULT,
    result,
})

export const addLog = (log: LogType): Actions => ({
    type: MAIN_VIEW_ACTIONS.ADD_LOG,
    log,
})

export const setCapture = (capture: HTMLCanvasElement): Actions => ({
    type: MAIN_VIEW_ACTIONS.SET_CAPTURE,
    capture,
})

export const setFeedbackCapture = (capture: HTMLCanvasElement): Actions => ({
    type: MAIN_VIEW_ACTIONS.SET_FEEDBACK_CAPTURE,
    capture,
})

export const setFnFeedback = (fn: () => void): Actions => ({
    type: MAIN_VIEW_ACTIONS.SET_FN_FEEDBACK,
    fn,
})

export const setDoPredict = (value: -1 | 0 | 1): Actions => ({
    type: MAIN_VIEW_ACTIONS.SET_DO_PREDICT,
    predict: value,
})

export const reducer = (state: State, action: Actions): State => {
    switch (action.type) {
        case MAIN_VIEW_ACTIONS.SET_RESULT:
            return {
                ...state,
                result: action.result,
            }
        case MAIN_VIEW_ACTIONS.ADD_LOG:
            return {
                ...state,
                logs: [action.log, ...state.logs],
            }
        case MAIN_VIEW_ACTIONS.SET_CAPTURE:
            return {
                ...state,
                capture: action.capture,
            }
        case MAIN_VIEW_ACTIONS.SET_FEEDBACK_CAPTURE:
            return {
                ...state,
                feedbackCapture: action.capture,
            }
        case MAIN_VIEW_ACTIONS.SET_FN_FEEDBACK:
            return {
                ...state,
                openFeedback: action.fn,
            }
        case MAIN_VIEW_ACTIONS.SET_DO_PREDICT:
            return {
                ...state,
                doPredict:
                    action.predict === 0
                        ? 0
                        : Math.max(state.doPredict + action.predict, 0),
            }
        default:
            return state
    }
}
