import React from "react"
import "./App.scss"
import AppView from "./pages/AppView"

function App() {
    return (
        <div className="App bg-gray-100">
            <AppView />
        </div>
    )
}

export default App
