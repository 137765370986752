import React from "react"
import Button from "assets/forms/Button"
import { FiHome, FiCamera, FiBarChart } from "react-icons/fi"
import { useHistory, useLocation } from "react-router-dom"

const NavBar = () => {
    const history = useHistory()
    const { pathname } = useLocation()

    const handleChangeRouter = (path: string) => {
        history.push(path)
    }

    const matchLocation = (path: string) => {
        const regex = new RegExp(path)
        return regex.test(pathname)
    }

    return (
        <div className="flex items-stretch h-16 bg-white shadow border-t">
            <div
                className="flex-grow flex-shrink-0 cursor-pointer flex items-center justify-center"
                onClick={() => handleChangeRouter("/home")}
            >
                <Button
                    className={`flex items-center justify-center text-xl ${
                        matchLocation("^/home") ? "text-gray-800" : "text-gray-500"
                    }`}
                >
                    <FiHome />
                </Button>
            </div>
            <div
                className="flex-grow flex-shrink-0 cursor-pointer flex items-center justify-center"
                onClick={() => handleChangeRouter("/")}
            >
                <Button
                    className={`flex items-center justify-center text-xl ${
                        matchLocation("^/$") ? "text-gray-800" : "text-gray-500"
                    }`}
                >
                    <FiCamera />
                </Button>
            </div>
            <div
                className="flex-grow flex-shrink-0 cursor-pointer flex items-center justify-center"
                onClick={() => handleChangeRouter("/history")}
            >
                <Button
                    className={`flex items-center justify-center text-xl ${
                        matchLocation("^/history") ? "text-gray-800" : "text-gray-500"
                    }`}
                >
                    <FiBarChart />
                </Button>
            </div>
        </div>
    )
}

export default NavBar
