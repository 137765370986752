import React from "react"
import { MONTHS } from "./months"
import { FiChevronLeft, FiTrash2 } from "react-icons/fi"
import { HistoryInterface, TrashTypes } from "helpers/history/type"
import { TrashDescriptionsWaste } from "helpers/trash/TrashDescriptionWaste"

interface props {
    month: typeof MONTHS[number]
    year: number
    scans?: HistoryInterface[]
    onClose: () => void
}

const HistoryMonth: React.FC<props> = ({ onClose, month, year, scans }) => {
    // Map<Date, Map<WasteType, History[]>>
    const scansByDate = new Map<
        number,
        Map<typeof TrashTypes[number], HistoryInterface[]>
    >()
    if (scans) {
        scans?.forEach(scan => {
            let scansFromMonth = scansByDate.get(scan.date.getDate())
            if (!scansFromMonth) {
                scansFromMonth = new Map<typeof TrashTypes[number], HistoryInterface[]>()
            }
            let wasteFromScanFromMonth = scansFromMonth.get(scan.wasteType)
            if (!wasteFromScanFromMonth) {
                wasteFromScanFromMonth = []
            }
            wasteFromScanFromMonth.push(scan)
            scansFromMonth.set(scan.wasteType, wasteFromScanFromMonth)
            scansByDate.set(scan.date.getDate(), scansFromMonth)
        })
    }

    return (
        <>
            <div className="px-4 pb-2 pt-6 shadow flex items-center gap-4 relative z-30 bg-white">
                <div>
                    <button
                        className="flex items-center bg-blue-100 self-start rounded-lg text-blue-800 font-bold text-sm px-3 py-2 gap-2"
                        onClick={() => onClose()}
                    >
                        <FiChevronLeft />
                        <div>Retour</div>
                    </button>
                </div>
                <div>
                    <div className="font-bold text-sm text-gray-500">Historique</div>
                    <div className="font-bold text-2xl leading-none">{month}</div>
                </div>
            </div>

            <div className="flex-grow overflow-y-scroll px-4 py-4 flex flex-col gap-2">
                {scans &&
                    Array.from(scansByDate.entries()).map(([date, wasteHistories]) => (
                        <div className="mt-4" key={date}>
                            <div className="px-3 text-sm font-bold text-gray-500">
                                {date} {month} {year}
                            </div>
                            {scansByDate.get(date) &&
                                Array.from(wasteHistories.entries()).map(
                                    ([wasteType, histories]) => (
                                        <div
                                            className="overflow-hidden rounded-md bg-white mt-1 shadow-sm"
                                            key={wasteType}
                                        >
                                            <div className="p-3 flex items-center gap-5 border-b">
                                                <div
                                                    className={
                                                        "flex items-center justify-center h-16 w-16 bg-" +
                                                        TrashDescriptionsWaste[wasteType]
                                                            ?.backgroundColor +
                                                        "-50 rounded-lg"
                                                    }
                                                >
                                                    <FiTrash2
                                                        color={
                                                            TrashDescriptionsWaste[
                                                                wasteType
                                                            ]?.iconColor
                                                        }
                                                    />
                                                </div>
                                                <div className="text-indigo-700 font-bold text-3xl">
                                                    {histories.length}
                                                </div>
                                                <div className="font-bold">
                                                    {
                                                        TrashDescriptionsWaste[wasteType]
                                                            .traduction
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                )}
                        </div>
                    ))}
            </div>
        </>
    )
}

export default HistoryMonth
