import React, { useCallback, useEffect, useState } from "react"
import { exportsWorker } from "./camera.worker"

import { Camera as CameraElm } from "assets/camera"

import {
    Actions,
    setResult,
    addLog,
    setCapture,
    LOG_KIND,
} from "helpers/reducers/main-view-reducer"
import { browser, data, Tensor3D, tidy } from "@tensorflow/tfjs"
import { WebcamIterator } from "@tensorflow/tfjs-data/dist/iterators/webcam_iterator"

interface props {
    dispatch: React.Dispatch<Actions>
    doPredict: boolean
}

const Camera: React.FC<props> = ({ dispatch, doPredict: doPredictBool }) => {
    const [cameraRef, setCameraRef] = useState<React.RefObject<HTMLVideoElement>>()
    const [webcam, setWebcam] = useState<WebcamIterator>()

    useEffect(() => {
        if (!cameraRef) {
            return
        }

        const load = async () => {
            if (!cameraRef.current) {
                return
            }

            const cameraIterator = await data.webcam(cameraRef.current, {
                facingMode: "environment",
                centerCrop: false,
            })

            setWebcam(cameraIterator)
        }

        load()
    }, [cameraRef])

    useEffect(() => {
        if (!webcam) {
            return
        }

        return () => {
            webcam.stop()
        }
    }, [webcam])

    const getPictureFromTensor = useCallback(async (tensor: Tensor3D): Promise<
        HTMLCanvasElement
    > => {
        const canvas = document.createElement("canvas")
        const data = tidy(() => tensor.toFloat().div(255))
        await browser.toPixels(data as any, canvas)

        data.dispose()

        return canvas
    }, [])

    useEffect(() => {
        if (!webcam) {
            return
        }

        if (!doPredictBool) {
            return
        }

        let stop = false

        const doPredict = async () => {
            await new Promise<void>(res => setTimeout(() => res(), 1000))

            while (true) {
                if (stop) {
                    return
                }

                let capture

                try {
                    capture = await webcam.capture()

                    dispatch(
                        addLog({
                            type: LOG_KIND.INFO,
                            title: "Capture",
                            content: `${Date.now()} Capture took`,
                        }),
                    )
                } catch (e) {
                    dispatch(
                        addLog({
                            type: LOG_KIND.ERROR,
                            title: "Can't take capture",
                            content: e.message || e,
                        }),
                    )
                }

                if (capture) {
                    const res = await exportsWorker.predict(capture)

                    const picture = await getPictureFromTensor(capture)

                    if (res) {
                        dispatch(setResult(res))
                        dispatch(setCapture(picture))
                        dispatch(
                            addLog({
                                type: LOG_KIND.INFO,
                                title: "AI",
                                content: `${Date.now()} AI done`,
                            }),
                        )
                    } else {
                        dispatch(
                            addLog({
                                type: LOG_KIND.ERROR,
                                title: "AI",
                                content: `No AI data`,
                            }),
                        )
                    }

                    capture.dispose()
                }

                await new Promise<void>(res => setTimeout(() => res(), 3000))
            }
        }

        doPredict()

        return () => {
            stop = true
        }
    }, [dispatch, webcam, getPictureFromTensor, doPredictBool])

    return (
        <div className="h-full">
            <div className={`h-full w-full`}>
                <CameraElm handleCameraRef={setCameraRef} />
            </div>
        </div>
    )
}

export default Camera
