import { TrashInterface } from "./TrashInterface"
import { TrashTypes } from "helpers/history/type"

export const TrashDescriptionsWaste: {
    [key in typeof TrashTypes[number]]: TrashInterface
} = {
    cardboard: {
        traduction: "Carton",
        fontColor: "yellow",
        backgroundColor: "yellow",
        iconColor: "orange",
        trashName: "jaune",
        description:
            "Quand vous déposez vos emballages dans le bac ou la box dédiée, ils sont collectés et acheminés dans un centre de tri. Dans ce centre, les papiers sont séparés des cartons et chacun sont triés selon leurs propriétés. Les matières sont ensuite compactées sous formes de balles qui sont expédiées chez des fabricants de l’industrie papetière. Les emballages cartons y sont broyés et placés dans une cuve remplie d’eau qui permettra la séparation des différents matériaux pour ne garder que la fibre destinées à la fabrication du papier.",
    },
    plastic: {
        traduction: "Plastique",
        fontColor: "yellow",
        backgroundColor: "yellow",
        iconColor: "orange",
        trashName: "jaune",
        description:
            "Les matières plastiques sont largement utilisées dans de nombreux domaines : emballages, automobile, électroménager, bâtiment, agriculture, etc. On retrouve donc les déchets plastiques dans l’ensemble des filières post-consommation. le recyclage des plastiques reste une tâche complexe. Néanmoins, face aux quantités de plastique utilisées et à leurs perspectives de croissance importantes, l’enjeu est aujourd’hui capital.",
    },
    metal: {
        traduction: "Métal",
        fontColor: "yellow",
        backgroundColor: "yellow",
        iconColor: "orange",
        trashName: "jaune",
        description:
            "Les métaux doivent être extraits dans des mines, et il s’agit d’une opération globalement polluante. Recycler prévient davantage de dégâts environnementaux miniers. Recycler du métal permet ainsi d’économiser de l’énergie, car le traitement du minerai brut implique l’utilisation de forts courants électriques pour séparer le métal de l’oxygène qu’il contient. De plus, une canette met 50 ans (on lit parfois 100 voire plus) pour se décomposer. La recycler évite de la laisser rouiller n’importe où.",
    },
    glass: {
        traduction: "Verre",
        fontColor: "green",
        backgroundColor: "green",
        iconColor: "green",
        trashName: "verte",
        description:
            "Recycler le verre est l’un des gestes écologiques les plus simples et efficaces que l’on puisse faire. En effet, le verre met 3 ou 4 millénaires à se décomposer dans la nature (mais on ne sait pas vraiment, en vérité), et créer du verre « neuf » à partir de verre recyclé consomme beaucoup moins d’énergie : une tonne de verre recyclé permet d’économiser une demie-tonne de CO2.",
    },
    paper: {
        traduction: "Papier",
        fontColor: "blue",
        backgroundColor: "blue",
        iconColor: "blue",
        trashName: "bleue",
        description:
            "Chaque Français consomme en moyenne 53 kg de papier par an. Au travail, un employé de bureau utilise plus de 2500 feuilles de papier chaque année ! Alors que la quasi-totalité des papiers sont recyclables, le taux de recyclage du papier en France n’est que de 55%. Selon la qualité du tri, le papier peut être recyclé de 3 à 7 fois et être transformé en nouvelles feuilles, en papier hygiénique ou encore en matériaux d’isolation.",
    },
    trash: {
        traduction: "Ordure ménagère",
        fontColor: "gray",
        backgroundColor: "gray",
        iconColor: "gray",
        trashName: "grise",
        description: "Nope",
    },
}
