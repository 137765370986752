import React from "react"

import styles from "./AppView.module.scss"
import NavBar from "layouts/navbar/NavBar"
import MainView from "./main-view/MainView"
import History from "./history/History"
import Home from "./home/Home"

import { HashRouter, Route } from "react-router-dom"

// interface CustomHTMLElement {
//     requestFullscreen?: () => void
//     mozRequestFullScreen?: () => void
//     webkitRequestFullscreen?: () => void
//     exitFullscreen?: () => void
//     webkitExitFullscreen?: () => void
//     mozExitFullscreen?: () => void
// }

const AppView = () => {
    // const [fullscreen, setFullscreen] = useState(false)

    // const toogleFullScreen = () => {
    //     const docElm = document.documentElement as CustomHTMLElement

    //     const tmpDoc = document as CustomHTMLElement

    //     if (!fullscreen) {
    //         if (docElm.requestFullscreen) {
    //             docElm.requestFullscreen()
    //         } else if (docElm.mozRequestFullScreen) {
    //             docElm.mozRequestFullScreen()
    //         } else if (docElm.webkitRequestFullscreen) {
    //             docElm.webkitRequestFullscreen()
    //         }
    //     } else {
    //         if (tmpDoc.exitFullscreen) {
    //             tmpDoc.exitFullscreen()
    //         } else if (tmpDoc.webkitExitFullscreen) {
    //             tmpDoc.webkitExitFullscreen()
    //         } else if (tmpDoc.mozExitFullscreen) {
    //             tmpDoc.mozExitFullscreen()
    //         }
    //     }

    //     setFullscreen(!fullscreen)
    // }

    return (
        <div className={styles.root}>
            <HashRouter>
                <div className="flex-grow overflow-hidden">
                    <Route path="/" exact>
                        <MainView />
                    </Route>
                    <Route path="/home">
                        <Home />
                    </Route>
                    <Route path="/history">
                        <History />
                    </Route>
                </div>
                <div className="flex-shrink-O">
                    <NavBar />
                </div>
            </HashRouter>
        </div>
    )
}

export default AppView
