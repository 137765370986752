import React, { useEffect, useMemo, useState } from "react"

import ApexCharts from "react-apexcharts"
import { DB } from "helpers/db"
import { ApexOptions } from "apexcharts"
import { HistoryInterface } from "helpers/history/type"
import { HistoryRepository } from "helpers/db"

interface props {}

const window = 15

export const Historygram: React.FC<props> = () => {
    const [wastes, setWastes] = useState<HistoryInterface[]>()

    useEffect(() => {
        const init = async () => {
            const repo = new HistoryRepository(DB)
            const today = new Date()
            const startRange = new Date(today)
            startRange.setDate(today.getDate() - window)

            const data = await repo.listBetweenDate(startRange, today)

            setWastes(data)
        }

        init()
    }, [])

    const history = useMemo(() => {
        if (!wastes) {
            return []
        }

        const _nowDate = new Date()
        const nowDate = new Date(
            _nowDate.getFullYear(),
            _nowDate.getMonth(),
            _nowDate.getDate(),
            0,
            0,
            0,
            0,
        )

        const data = new Array(window).fill(null).map((_, i) => {
            const newDate = new Date(nowDate)
            newDate.setDate(newDate.getDate() - window + i + 1)
            return {
                pivot: newDate.getTime(),
                count: 0,
            }
        })

        wastes.forEach(waste => {
            const newDate = new Date(
                waste.date.getFullYear(),
                waste.date.getMonth(),
                waste.date.getDate(),
                0,
                0,
                0,
                0,
            )

            const focusDate = data.find(da => da.pivot === newDate.getTime())

            if (focusDate) {
                focusDate.count++
            }
        })

        return data
    }, [wastes])

    const options = useMemo(
        (): ApexOptions => ({
            labels: history.map(his => {
                const pivot = new Date(his.pivot)
                return `${pivot.getDate().toString().padStart(2, "0")}/${(
                    pivot.getMonth() + 1
                )
                    .toString()
                    .padStart(2, "0")}`
            }),
            chart: {
                type: "area",
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
            },
            stroke: {
                curve: "smooth",
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
            dataLabels: {
                enabled: false,
            },
        }),
        [history],
    )

    return (
        <div>
            <ApexCharts
                options={options}
                type="area"
                series={[{ type: "area", data: history.map(hist => hist.count) }]}
            />
        </div>
    )
}
