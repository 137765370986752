import React from "react"

import { StatsHome } from "./Stats.Home"
import { Historygram } from "./Historygram"

const Home: React.FC = () => {
    return (
        <div className="flex flex-col h-full text-left overflow-hidden">
            <div className="flex-shrink-0 bg-white shadow relative z-10">
                <div className="ml-4 mt-6 mb-2 mr-4 font-bold text-2xl">Accueil</div>
            </div>
            <div className="flex-grow px-4 flex flex-wrap overflow-y-auto flex-shrink gap-4 py-4 items-start">
                <div className="flex-1" style={{ minWidth: 300 }}>
                    <div className="mb-2 font-bold">7 derniers jours</div>
                    <div className="bg-white rounded-md shadow-md p-4 overflow-x-auto">
                        <StatsHome window={7} />
                    </div>
                </div>
                <div className="flex-1" style={{ minWidth: 300 }}>
                    <div className="mb-2 font-bold">30 derniers jours</div>
                    <div className="bg-white rounded-md shadow-md p-4 overflow-x-auto">
                        <StatsHome window={30} />
                    </div>
                </div>
                <div className="flex-1" style={{ minWidth: 300 }}>
                    <div className="mb-2 font-bold">Fréquence des scans</div>
                    <div className="bg-white rounded-md shadow-md p-4 overflow-x-auto">
                        <Historygram />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
