import React, { useEffect, useReducer, useCallback, useState } from "react"

import Camera from "layouts/camera/Camera"
import {
    initalState,
    reducer,
    setFnFeedback,
    setDoPredict,
} from "helpers/reducers/main-view-reducer"
import ResultScren from "layouts/result-screen/ResultScreen"
import Feedback from "layouts/feedback/Feedback"
import { AppHelper } from "layouts/app-helper/AppHelper"
import { TutorialButton } from "layouts/tutorial-button/TutorialButton"

// import styles from "./MainView.module.scss"

const Capture = () => {
    const [state, dispatch] = useReducer(reducer, initalState)
    const [openFeedback, setOpenFeedback] = useState(false)
    const [openHelp, setOpenHelp] = useState(false)

    const openFeedbackFn = useCallback(() => {
        setOpenFeedback(true)
    }, [])

    useEffect(() => {
        dispatch(setFnFeedback(openFeedbackFn))
    }, [openFeedbackFn])

    useEffect(() => {
        dispatch(setDoPredict(0))
    }, [])

    useEffect(() => {
        dispatch(setDoPredict(openFeedback ? 1 : -1))
    }, [openFeedback])

    const handleDoPredict = useCallback(
        (value: boolean) => {
            if (value) {
                dispatch(setDoPredict(-1))
            } else {
                dispatch(setDoPredict(1))
            }
        },
        [dispatch],
    )

    return (
        <div className="h-full relative max-h-full">
            <Camera dispatch={dispatch} doPredict={state.doPredict === 0} />
            <div className="h-full absolute top-0 w-full">
                <ResultScren state={state} dispatch={dispatch} />
            </div>
            <TutorialButton open={openHelp} setOpen={setOpenHelp} />
            <Feedback
                open={openFeedback}
                onClose={() => setOpenFeedback(false)}
                state={state}
            />
            <AppHelper
                doPredict={handleDoPredict}
                open={openHelp}
                setOpen={setOpenHelp}
            />
        </div>
    )
}

export default Capture
