export const cloneCanvas = (
    canvasBase: HTMLCanvasElement,
): HTMLCanvasElement | undefined => {
    //create a new canvas
    const newCanvas = document.createElement("canvas")
    const context = newCanvas.getContext("2d")

    if (!context) {
        return undefined
    }

    //set dimensions
    newCanvas.width = canvasBase.width
    newCanvas.height = canvasBase.height

    //apply the old canvas to the new one
    context.drawImage(canvasBase, 0, 0)

    //return the new canvas
    return newCanvas
}
