import type { TrashTypes } from "helpers/history/type"

export const IMAGENET_CLASSES: Record<number, typeof TrashTypes[number]> = {
    0: "cardboard",
    1: "glass",
    2: "metal",
    3: "paper",
    4: "plastic",
    5: "trash",
}
