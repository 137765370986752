import React, { useCallback, useEffect, useMemo, useState } from "react"

import {
    State,
    Actions,
    setFeedbackCapture,
    setDoPredict,
} from "helpers/reducers/main-view-reducer"

import { TrashTypes } from "helpers/history/type"

import styles from "./ResultScreen.module.scss"
import WasteDetailsPanel from "layouts/waste-details/WasteDetailsPanel"
import { FiTrash } from "react-icons/fi"
import { cloneCanvas } from "helpers/clone-canvas"
import { TrashDescriptionsWaste } from "helpers/trash/TrashDescriptionWaste"

import { a, useSpring } from "react-spring"

interface props {
    state: State
    dispatch: React.Dispatch<Actions>
}

const THRESHOLD = 0.7

const ResultScren: React.FC<props> = ({ state, dispatch }) => {
    const [showHints, setShowHints] = useState<
        [typeof TrashTypes[number], typeof TrashTypes[number]]
    >([TrashTypes[0], TrashTypes[0]])
    const [displayHint, setDisplayHint] = useState<[boolean, boolean]>([false, false])
    const [openDetails, setOpenDetails] = useState(false)
    const [wasteDetailType, setWasteDetailType] = useState<typeof TrashTypes[number]>()
    const [openendCanvas, setOpenedCanvas] = useState<HTMLCanvasElement>()

    const [displayHint1, displayHint2] = displayHint

    const validHints = useMemo(() => {
        if (!state.result) {
            return []
        }
        return state.result[0].sort((a, b) => b.value - a.value)
    }, [state.result])

    useEffect(() => {
        dispatch(setDoPredict(openDetails ? 1 : -1))
    }, [dispatch, openDetails])

    useEffect(() => {
        let update = false
        const newValue: [typeof TrashTypes[number], typeof TrashTypes[number]] = [
            showHints[0],
            showHints[1],
        ]

        if (
            validHints[0] &&
            validHints[0].value > THRESHOLD &&
            validHints[0].label !== showHints[0]
        ) {
            newValue[0] = validHints[0].label
            update = true
        }

        if (
            validHints[1] &&
            validHints[1].value > THRESHOLD &&
            validHints[1].label !== showHints[1]
        ) {
            newValue[1] = validHints[1].label
            update = true
        }

        if (update) {
            setShowHints(newValue)
        }
    }, [validHints, showHints])

    useEffect(() => {
        const showItem1 = !!validHints[0] && validHints[0].value > THRESHOLD
        const showItem2 = !!validHints[1] && validHints[1].value > THRESHOLD

        setDisplayHint([showItem1, showItem2])
    }, [validHints])

    const { opacity: opacity1 } = useSpring({
        opacity: displayHint[0] ? 1 : 0,
    })

    const { opacity: opacity2 } = useSpring({
        opacity: displayHint[1] ? 1 : 0,
    })

    const [styleIssue, set] = useSpring(() => ({
        maxHeight: 0,
        opacity: 0,
    }))

    useEffect(() => {
        if (!displayHint1 && !displayHint2) {
            const cancelTimeout = setTimeout(() => {
                set({ maxHeight: 200, opacity: 1 })
            }, 5000)

            return () => {
                clearTimeout(cancelTimeout)
            }
        } else {
            set({ opacity: 0, maxHeight: 0 })
        }
    }, [set, displayHint1, displayHint2])

    const { capture, openFeedback } = state

    const handleOpenDetails = useCallback(
        async (type: typeof TrashTypes[number]) => {
            let clonedCanvas

            if (capture) {
                clonedCanvas = cloneCanvas(capture)
            }

            clonedCanvas && setOpenedCanvas(clonedCanvas)

            setWasteDetailType(type)
            setOpenDetails(true)
        },
        [capture],
    )

    const handleOpenFeedback = useCallback(() => {
        if (!capture || !openFeedback) {
            return
        }

        const clonedCanvas = cloneCanvas(capture)

        if (!clonedCanvas) {
            return
        }

        dispatch(setFeedbackCapture(clonedCanvas))
        openFeedback()
    }, [capture, openFeedback, dispatch])

    const handleOpenFeedbackOpen = useCallback(() => {
        if (!openendCanvas || !openFeedback) {
            return
        }

        dispatch(setFeedbackCapture(openendCanvas))
        openFeedback()
    }, [openendCanvas, openFeedback, dispatch])

    const trashInfos = useMemo(() => {
        return [
            TrashDescriptionsWaste[showHints[0]],
            TrashDescriptionsWaste[showHints[1]],
        ] as const
    }, [showHints])

    return (
        <div className="h-full w-full">
            <div className={styles.pointer}></div>
            <div className={styles.hints}>
                <div className="flex max-w-screen-sm px-4 gap-4 m-auto">
                    <a.div
                        style={{ opacity: opacity1 as any }}
                        className={`bg-white text-base font-bold flex gap-4 items-center rounded-full justify-start p-3 text-center h-12 text-yellow-600 ${styles.itemHint}`}
                        onClick={() => handleOpenDetails(showHints[0])}
                    >
                        <div
                            className={`rounded-full h-8 w-8 flex items-center justify-center bg-${trashInfos[0]?.backgroundColor}-100`}
                        >
                            <FiTrash
                                className="text-base"
                                color={trashInfos[0]?.iconColor}
                            />
                        </div>
                        <div className={`text-${trashInfos[0]?.fontColor}-900`}>
                            {trashInfos[0]?.traduction}
                        </div>
                    </a.div>
                    <a.div
                        style={{ opacity: opacity2 as any }}
                        className={`bg-white text-base font-bold flex gap-4 items-center rounded-full justify-start p-3 text-center h-12 text-gray-800 ${styles.itemHint}`}
                        onClick={() => handleOpenDetails(showHints[1])}
                    >
                        <div
                            className={`rounded-full bg-green-100 h-8 w-8 flex items-center justify-center bg-${trashInfos[1]?.backgroundColor}-100`}
                        >
                            <FiTrash
                                className="text-green-500 text-xl"
                                color={trashInfos[1]?.iconColor}
                            />
                        </div>
                        <div className={`text-${trashInfos[1]?.fontColor}-900`}>
                            {trashInfos[1]?.traduction}
                        </div>
                    </a.div>
                </div>
                <a.div
                    style={{
                        opacity: styleIssue.opacity as any,
                        maxHeight: styleIssue.maxHeight,
                    }}
                    className={`text-sm max-w-screen-sm m-auto p-2 ${styles.issue} flex justify-end`}
                >
                    <div onClick={() => handleOpenFeedback()}>Un problème ?</div>
                </a.div>
            </div>
            {wasteDetailType && (
                <WasteDetailsPanel
                    open={openDetails}
                    onChange={open => {
                        setOpenDetails(open)
                    }}
                    wasteType={wasteDetailType}
                    showFeedback={() => handleOpenFeedbackOpen()}
                />
            )}
        </div>
    )
}

export default ResultScren
