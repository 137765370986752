import React, { useCallback, useEffect } from "react"

import { a, useSpring } from "react-spring"
import { useDrag } from "react-use-gesture"

import WasteDetails from "./WasteDetails"
import type { TrashTypes } from "helpers/history/type"

interface props {
    open: boolean
    onChange: (open: boolean) => void
    wasteType: typeof TrashTypes[number]
    showFeedback: () => void
}

const WasteDetailsPanel: React.FC<props> = ({
    open,
    onChange,
    wasteType,
    showFeedback,
}) => {
    const getClientHeight = useCallback(() => {
        return window.innerHeight
    }, [])

    const [stylePanel, set] = useSpring(() => ({
        top: getClientHeight(),
        y: 0,
    }))

    const [styleBackground, setStyleBackground] = useSpring(() => ({
        display: "none",
        backdropFilter: "blur(0px) brightness(100%)",
    }))

    useEffect(() => {
        const handle = async () => {
            if (open) {
                await setStyleBackground({ display: "flex", immediate: true })
            }

            await Promise.all([
                set({
                    top: open ? 0 : getClientHeight(),
                    y: 0,
                }),
                setStyleBackground({
                    backdropFilter: open
                        ? "blur(20px)  brightness(40%)"
                        : "blur(0px)  brightness(100%)",
                }),
            ])

            if (!open) {
                await setStyleBackground({
                    display: "none",
                    immediate: true,
                })
            }
        }

        handle()
    }, [open, set, getClientHeight, setStyleBackground])

    const bind = useDrag(({ movement, dragging, direction }) => {
        if (dragging) {
            if (open && movement[1] > 0) {
                if (Math.abs(movement[1]) < 40) {
                    set({
                        y: 0,
                    })
                    return
                }

                set({
                    y: movement[1],
                })
            }
        } else {
            if (Math.abs(movement[1]) > 200 && direction[1] * (open ? 1 : -1) > 0) {
                onChange(!open)
            } else {
                set({
                    y: 0,
                })
            }
        }
    }, {})

    return (
        <a.div
            style={styleBackground as any}
            className="h-full w-full max-h-full overflow-hidden flex-col"
        >
            <a.div
                {...bind()}
                style={stylePanel}
                className="bg-white rounded-t-lg shadow-xl flex flex-col flex-grow overflow-hidden mt-12 relative"
            >
                <div className="flex justify-center p-4">
                    <div className="bg-gray-300 rounded-full w-12 h-1"></div>
                </div>
                <div className="flex-grow flex-shrink overflow-y-hidden">
                    <WasteDetails
                        wasteType={wasteType}
                        onChange={() => onChange(false)}
                        openFeedback={() => showFeedback()}
                    />
                </div>
            </a.div>
        </a.div>
    )
}

export default WasteDetailsPanel
