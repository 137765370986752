import React, { useEffect, useMemo, useState } from "react"
import { FiChevronRight } from "react-icons/fi"
import { useSpring, a } from "react-spring"
import WelcomeImg from "./welcome.svg"
import CaptureImg from "./capture.svg"
import ActionImg from "./button-action.svg"
import ButtonScreen from "./button-screen.png"
import SaveHistoryImg from "./save-history.svg"

const FIRST_TIME_LOCAL_KEY = "__FIRST_OPEN_DONE__"

enum STEPS {
    ONE = 0,
    TWO = 1,
    THREE = 2,
    FOUR = 3,
}

interface props {
    doPredict: (value: boolean) => void
    open: boolean
    setOpen: (value: boolean) => void
}

export const AppHelper: React.FC<props> = ({ doPredict, open, setOpen }) => {
    const firstTime = useMemo(() => {
        return localStorage.getItem(FIRST_TIME_LOCAL_KEY) !== "FALSE"
    }, [])

    const [hideDiv, setHideDiv] = useState(false)

    const [springsModals, set] = useSpring(
        () => ({
            opacity: 0,
        }),
        [],
    )

    const [step, setStep] = useState<STEPS>(STEPS.ONE)

    const springSteps = useSpring({ right: `${step * 100}%` })

    useEffect(() => {
        if (open) {
            setStep(STEPS.ONE)
            doPredict(false)
        } else {
            doPredict(true)
        }
    }, [open, doPredict])

    useEffect(() => {
        if (firstTime) {
            setOpen(true)
        }
    }, [firstTime, setOpen])

    useEffect(() => {
        const init = async ($open: boolean) => {
            if ($open) {
                setHideDiv(false)
                await set({ opacity: 1 })
            } else {
                await set({ opacity: 0 })
                setHideDiv(true)
            }
        }

        init(open)
    }, [open, set])

    const handleStart = () => {
        setOpen(false)
        localStorage.setItem(FIRST_TIME_LOCAL_KEY, "FALSE")
    }

    if (!hideDiv) {
        return (
            <a.div
                className="absolute top-0 left-0 h-full w-full p-8 z-30 overflow-y-auto flex flex-col items-center justify-start"
                style={{ opacity: springsModals.opacity as any }}
            >
                <div
                    className="bg-white rounded-lg flex items-stretch overflow-hidden flex-shrink-0 flex-grow w-full"
                    style={{ maxWidth: 400 }}
                >
                    <a.div
                        className="flex flex-col w-full flex-shrink-0 relative"
                        style={{ right: springSteps.right }}
                    >
                        <div className="p-12">
                            <img src={WelcomeImg} alt="Welcome illustration" />
                        </div>
                        <div className="text-xl uppercase font-bold text-center text-red-900">
                            Bonjour !
                        </div>
                        <div className="flex-grow text-center text-sm text-gray-700 mt-4 px-4">
                            <p className="text-black">
                                Voici un petit guide pour vous apprendre à utiliser
                                l'application.
                            </p>
                        </div>
                        <div
                            className="flex justify-center items-center gap-4 bg-red-50 px-4 py-3 text-red-800"
                            onClick={() => setStep(STEPS.TWO)}
                        >
                            <div className="uppercase font-bold text-md">Suivant</div>
                            <FiChevronRight />
                        </div>
                    </a.div>
                    <a.div
                        className="flex flex-col w-full flex-shrink-0 relative"
                        style={{ right: springSteps.right }}
                    >
                        <div className="p-12">
                            <img src={CaptureImg} alt="Capture illustration" />
                        </div>
                        <div className="text-xl uppercase font-bold text-center text-red-900">
                            Scan
                        </div>
                        <div className="flex-grow text-center text-sm text-gray-700 mt-4 px-4">
                            <p className="text-black">
                                Pointez votre caméra sur l'objet à analyser.
                            </p>
                        </div>
                        <div
                            className="flex justify-center items-center gap-4 bg-red-50 px-4 py-3 text-red-800"
                            onClick={() => setStep(STEPS.THREE)}
                        >
                            <div className="uppercase font-bold text-md">Suivant</div>
                            <FiChevronRight />
                        </div>
                    </a.div>
                    <a.div
                        className="flex flex-col w-full flex-shrink-0 relative"
                        style={{ right: springSteps.right }}
                    >
                        <div className="p-12">
                            <img src={ActionImg} alt="Action illustration" />
                        </div>
                        <div className="flex-grow text-center text-sm text-gray-700 mt-4 px-4">
                            <p className="text-black">
                                Cliquer sur le bouton pour afficher des informations
                                supplémentaires sur l'objet.
                            </p>
                            <div className="flex justify-center">
                                <img
                                    src={ButtonScreen}
                                    alt="Button d'action"
                                    className="mt-8 rounded-lg overflow-hidden"
                                    style={{ maxWidth: 200 }}
                                />
                            </div>
                        </div>
                        <div
                            className="flex justify-center items-center gap-4 bg-red-50 px-4 py-3 text-red-800"
                            onClick={() => setStep(STEPS.FOUR)}
                        >
                            <div className="uppercase font-bold text-md">Suivant</div>
                            <FiChevronRight />
                        </div>
                    </a.div>
                    <a.div
                        className="flex flex-col w-full flex-shrink-0 relative"
                        style={{ right: springSteps.right }}
                    >
                        <div className="p-12">
                            <img
                                src={SaveHistoryImg}
                                alt="Save in history illustration"
                            />
                        </div>
                        <div className="text-xl uppercase font-bold text-center text-red-900">
                            Historique
                        </div>
                        <div className="flex-grow text-center text-sm text-gray-700 mt-4 px-4">
                            <p className="text-black">
                                Cliquez sur <strong>Sauvegarder</strong> pour retrouver ce
                                scan dans l'historique des scans.
                            </p>
                        </div>
                        <div
                            className="flex justify-center items-center gap-4 bg-red-50 px-4 py-3 text-red-800"
                            onClick={() => handleStart()}
                        >
                            <div className="uppercase font-bold text-md">Commencer</div>
                            <FiChevronRight />
                        </div>
                    </a.div>
                </div>
            </a.div>
        )
    }

    return <></>
}
