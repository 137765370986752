import React, { useEffect, useMemo, useState } from "react"

import { HistoryRepository } from "helpers/db/history/db-repository"
import { DB } from "helpers/db"
import { HistoryInterface, TrashTypes } from "helpers/history/type"
import { TrashDescriptionsWaste } from "helpers/trash/TrashDescriptionWaste"
import ApexCharts from "react-apexcharts"
import { ApexOptions } from "apexcharts"

interface props {
    window: number
}

export const StatsHome: React.FC<props> = ({ window }) => {
    const [wastes, setWastes] = useState<HistoryInterface[]>()

    useEffect(() => {
        const init = async () => {
            const repo = new HistoryRepository(DB)
            const today = new Date()
            const startRange = new Date(today)
            startRange.setDate(today.getDate() - window)

            const data = await repo.listBetweenDate(startRange, today)

            setWastes(data)
        }

        init()
    }, [window])

    const calcs = useMemo(() => {
        const base: {
            [key in typeof TrashTypes[number]]: number
        } = TrashTypes.map(trash => ({ [trash]: 0 })).reduce(
            (prev, curr) => ({ ...prev, ...curr }),
            {},
        ) as any

        wastes?.forEach(waste => (base[waste.wasteType] = base[waste.wasteType] + 1))

        return base
    }, [wastes])

    const options = useMemo(
        (): ApexOptions => ({
            labels: (Object.keys(calcs) as (keyof typeof calcs)[]).map(
                trash => TrashDescriptionsWaste[trash].traduction,
            ),
            chart: {
                type: "donut",
            },
        }),
        [calcs],
    )

    return (
        <div className="">
            <ApexCharts
                series={Object.values(calcs)}
                options={options}
                type="donut"
                height={400}
                width={400}
            />
        </div>
    )
}
