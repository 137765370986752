import React from "react"
import { FiSave, FiTrash2 } from "react-icons/fi"

import { DB, HistoryRepository } from "helpers/db"
import { TrashDescriptionsWaste } from "helpers/trash/TrashDescriptionWaste"
import type { TrashTypes } from "helpers/history/type"

interface props {
    wasteType: typeof TrashTypes[number]
    onChange: () => void
    openFeedback: () => void
}

const WasteDetails: React.FC<props> = ({ wasteType, onChange, openFeedback }) => {
    const trashInfos = TrashDescriptionsWaste[wasteType]

    const handleSave = async () => {
        const repo = new HistoryRepository(DB)
        await repo.add({
            wasteType,
            date: new Date(),
        })
        onChange()
    }

    return (
        <div className="flex flex-col h-full overflow-y-hidden">
            <div className="flex px-4">
                <div className="flex justify-center items-center h-32 w-32 bg-gray-200 rounded-lg">
                    <FiTrash2 size={80} color={trashInfos?.iconColor} />
                </div>
                <div className="flex-grow ml-4 text-left">
                    <div className="text-xl font-extrabold text-red-700">
                        {trashInfos?.traduction}
                    </div>
                    <div className="flex gap-2 mt-2 items-stretch">
                        <div
                            className={
                                "w-8 h-8 bg-gray-200 rounded text-" +
                                trashInfos?.fontColor +
                                "-500 font-bold text-center flex items-center justify-center"
                            }
                        >
                            {trashInfos?.trashName.slice(0, 1)?.toUpperCase()}
                        </div>
                        <div
                            className={`bg-${trashInfos?.backgroundColor}-300 text-xs font-extrabold text-${trashInfos?.fontColor}-900 flex items-center px-2 rounded uppercase`}
                        >
                            Poubelle {trashInfos?.trashName}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-100 flex-grow mt-8 pb-8 text-left overflow-y-auto flex-shrink">
                <div className="m-4 mt-6 text-lg font-extrabold text-gray-700">
                    Description
                </div>
                <div className="text-xs mx-4 bg-gray-200 rounded p-4 text-gray-800">
                    {TrashDescriptionsWaste[wasteType]?.description}
                </div>
                <div className="flex items-stretch gap-2 mt-6 mx-4">
                    <button
                        onClick={() => handleSave()}
                        className="bg-indigo-600 rounded text-white text-sm font-bold py-2 px-4 flex items-center gap-2"
                    >
                        <FiSave />
                        <div>Sauvegarder</div>
                    </button>
                    <button
                        className="text-sm px-4 py-2 text-indigo-600 font-bold flex items-center gap-2"
                        onClick={() => onChange()}
                    >
                        <div>Retour</div>
                    </button>
                </div>
                <button
                    className="mx-4 mt-4 text-sm text-red-300 underline text-left"
                    onClick={() => openFeedback()}
                >
                    La description ne correspond pas à l'objet ?
                </button>
            </div>
        </div>
    )
}

export default WasteDetails
