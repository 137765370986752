import React from "react"
import { BaseProps, useBaseProps } from "assets/helpers/base-component"

interface props extends BaseProps {
    onClick?: () => void
}

const Button: React.FC<props> = ({ onClick, children, ...props }) => {
    const rootProps = useBaseProps(props)

    return (
        <div {...rootProps} onClick={onClick}>
            {children}
        </div>
    )
}

export default Button
