import { MONTHS } from "./months"

export const gradients: {
    [key in typeof MONTHS[number]]: {
        start: string
        end: string
    }
} = {
    Janvier: {
        start: "#00D448",
        end: "#00BDDD",
    },
    Février: {
        start: "#FFC100",
        end: "#00D448",
    },
    Mars: {
        start: "#F80800",
        end: "#FFC100",
    },
    Avril: {
        start: "#E930EF",
        end: "#F80800",
    },
    Mai: {
        start: "#6000F8",
        end: "#E930EF",
    },
    Juin: {
        start: "#00D448",
        end: "#00BDDD",
    },
    Juillet: {
        start: "#FFC100",
        end: "#00D448",
    },
    Août: {
        start: "#F80800",
        end: "#FFC100",
    },
    Septembre: {
        start: "#E930EF",
        end: "#F80800",
    },
    Octobre: {
        start: "#6000F8",
        end: "#E930EF",
    },
    Novembre: {
        start: "#00D448",
        end: "#00BDDD",
    },
    Décembre: {
        start: "#FFC100",
        end: "#00D448",
    },
}
