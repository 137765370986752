import React from "react"
import { FiHelpCircle } from "react-icons/fi"
import styles from "./TutorialButton.module.scss"
import { a } from "react-spring"

interface props {
    open: boolean
    setOpen: (value: boolean) => void
}

export const TutorialButton: React.FC<props> = ({ open, setOpen }) => {
    return (
        <div>
            {!open && (
                <div className={styles.container}>
                    <a.div
                        className={styles.help}
                        onClick={() => {
                            setOpen(true)
                        }}
                    >
                        <span className={styles.helpLabel}>Aide</span>
                        <FiHelpCircle />
                    </a.div>
                </div>
            )}
        </div>
    )
}
