import React, { useEffect, useRef, useState } from "react"
import { FiLoader, FiSend } from "react-icons/fi"
import { a, useSpring } from "react-spring"
import { useDrag } from "react-use-gesture"

import { State } from "helpers/reducers/main-view-reducer"
import { sendFeedback } from "helpers/feedbacks/api"

import { TrashDescriptionsWaste } from "helpers/trash/TrashDescriptionWaste"
import styles from "./Feedback.module.scss"

interface props {
    open: boolean
    onClose: () => void
    state: State
}

const Feedback: React.FC<props> = ({ onClose, open, state }) => {
    const [panelSpring, setPanel] = useSpring(() => ({
        top: "100%",
        display: "none" as "flex" | "none",
        y: 0,
    }))

    const [apiLoading, setApiLoading] = useState(false)

    const [category, setCategory] = useState<string>()

    const imageRef = useRef<HTMLDivElement>(null)

    const listRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleAnim = async () => {
            if (open) {
                await setPanel({ display: "flex", immediate: true })
                await setPanel({ top: "0%" })
            } else {
                await setPanel({ top: "100%" })
                await setPanel({ display: "none", immediate: true })
            }
        }

        handleAnim()
    }, [open, setPanel])

    const bind = useDrag(({ dragging, movement, direction, event }) => {
        if (
            listRef &&
            listRef.current &&
            event &&
            (listRef.current === event.target ||
                listRef.current.contains(event.target as any))
        ) {
            return
        }

        if (dragging) {
            if (movement[1] > 0) {
                if (Math.abs(movement[1]) < 40) {
                    setPanel({
                        y: 0,
                    })
                    return
                }

                setPanel({
                    y: movement[1],
                })
            }
        } else {
            if (Math.abs(movement[1]) > 200 && direction[1] > 0) {
                onClose()
                setPanel({
                    y: 0,
                })
            }

            setPanel({
                y: 0,
            })
        }
    })

    useEffect(() => {
        if (!imageRef.current || !state.feedbackCapture) {
            return
        }

        while (imageRef.current.lastChild) {
            imageRef.current.removeChild(imageRef.current.lastChild)
        }

        imageRef.current.appendChild(state.feedbackCapture)
    }, [imageRef, state.feedbackCapture])

    const handleSubmit = async () => {
        if (apiLoading) {
            return
        }

        let base64URL = state.feedbackCapture?.toDataURL()

        if (!base64URL || !category) {
            return
        }

        base64URL = base64URL.replace("data:image/png;base64,", "")

        setApiLoading(true)
        await sendFeedback(base64URL, category)
        setCategory(undefined)
        setApiLoading(false)

        onClose()
    }

    return (
        <a.div
            {...bind()}
            style={panelSpring}
            className="h-full absolute top-0 w-full flex flex-col z-40"
        >
            <div className="h-12 flex-shrink-0"></div>
            <div
                className={`flex-grow bg-white shadow-xl flex flex-col text-left overflow-hidden flex-shrink ${styles.panel}`}
            >
                <div className="flex justify-center py-4">
                    <div className="bg-gray-300 w-12 rounded-full h-1"></div>
                </div>
                <div className="px-4 font-bold text-md">
                    Suggerez-nous des améliorations
                </div>
                <div className={`px-4 mt-4 h-40 ${styles.imageDiv}`} ref={imageRef}>
                    <div
                        className={`rounded-md h-full overflow-hidden bg-gray-100 object-cover`}
                    />
                </div>
                <div className="px-4 mt-8 text-sm font-bold">
                    A quoi correspond cette image ?
                </div>
                <div
                    className="flex-grow flex-shrink overflow-y-scroll my-2 py-2 bg-gray-50 flex flex-col gap-2"
                    ref={listRef}
                >
                    {keysType(TrashDescriptionsWaste).map((wasteType, pos) => (
                        <button
                            key={pos}
                            className={`mx-4 ${
                                wasteType === category
                                    ? "bg-blue-800 text-white"
                                    : "bg-white"
                            } border border-gray-100 shadow-sm rounded-md text-left flex items-center gap-4`}
                            onClick={() => setCategory(wasteType)}
                        >
                            <div className="bg-gray-300 bg-opacity-20 rounded w-12 h-12 m-2"></div>
                            <div>{TrashDescriptionsWaste[wasteType].traduction}</div>
                        </button>
                    ))}
                </div>
                <div className="flex items-center mb-4 px-4 gap-2 text-sm">
                    <div>
                        <button
                            className="rounded bg-indigo-600 text-white py-2 px-4 flex items-center gap-4 font-bold"
                            onClick={() => handleSubmit()}
                        >
                            <FiSend />
                            {!apiLoading ? (
                                <div>Envoyer</div>
                            ) : (
                                <FiLoader className={styles.rotating} />
                            )}
                        </button>
                    </div>
                    <div className="text-indigo-600 mx-4">
                        <button onClick={onClose} className="font-bold">
                            Annuler
                        </button>
                    </div>
                </div>
            </div>
        </a.div>
    )
}

export default Feedback
