export const TrashTypes = [
    "cardboard",
    "plastic",
    "metal",
    "glass",
    "paper",
    "trash",
] as const

export interface HistoryInterface {
    id: number
    wasteType: typeof TrashTypes[number]
    date: Date
}
