import { Tensor3D } from "@tensorflow/tfjs"

import { AI } from "helpers/ai"

const Ai = new AI()

Ai.loadModel()

export const exportsWorker = {
    async predict(input: Tensor3D) {
        const data = await Ai.predict(input)
        return data
    },
}

export type exportsType = typeof exportsWorker
