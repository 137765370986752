import React, { useEffect, useRef } from "react"

interface props {
    handleCameraRef?: (
        videoStream: React.RefObject<HTMLVideoElement> | undefined,
        error?: string,
    ) => void
}

export const Camera: React.FC<props> = ({ handleCameraRef }) => {
    const videoRef = useRef<HTMLVideoElement>(null)

    useEffect(() => {
        if (!handleCameraRef) {
            return
        }

        handleCameraRef(videoRef)
    }, [videoRef, handleCameraRef])

    return (
        <div className="h-full w-full flex justify-center">
            <video
                ref={videoRef}
                autoPlay
                playsInline
                muted
                className="h-full w-full object-cover"
                width="1920"
                height="1080"
            ></video>
        </div>
    )
}
