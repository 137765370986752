import React, { useEffect, useState } from "react"

import { FiChevronRight } from "react-icons/fi"
import { HistoryInterface } from "helpers/history/type"
import { MONTHS } from "./months"
import { gradients } from "./history-gradient"
import HistoryMonth from "./HistoryMonth"
import { a, useSpring } from "react-spring"
import ReactLoading from "react-loading"
import { HistoryRepository } from "../../helpers/db/history/db-repository"
import { DB } from "helpers/db/db-provider"

const History: React.FC = () => {
    const [showMonth, setShowMonth] = useState<boolean>(false)
    const [scans, setScans] = useState<Map<string, HistoryInterface[]>>()
    const [focus, setFocus] = useState<{
        year: number
        month: typeof MONTHS[number]
        scans?: HistoryInterface[]
    }>()

    const mainSpring = useSpring({
        left: showMonth ? "-50%" : "0%",
    })

    const monthSpring = useSpring({
        left: showMonth ? "0%" : "100%",
    })

    const fetchHistoricScans = async () => {
        const fetchScans = await new HistoryRepository(DB).list(100000)
        const scansByMonths = new Map<string, HistoryInterface[]>()
        fetchScans.forEach(scan => {
            let scansFromMonth = scansByMonths.get(MONTHS[scan.date.getMonth()])
            if (!scansFromMonth) {
                scansFromMonth = []
            }
            scansFromMonth.push(scan)
            scansByMonths.set(MONTHS[scan.date.getMonth()], scansFromMonth)
        })
        setScans(scansByMonths)
    }

    useEffect(() => {
        fetchHistoricScans()
    }, [])

    return (
        <div className="h-full flex justify-center">
            <div className="h-full flex flex-col text-left flex-grow relative">
                <a.div
                    style={mainSpring}
                    className="flex flex-col h-full absolute w-full bg-gray-100"
                >
                    <div className="pl-4 pt-6 pb-2 pr-4 font-bold text-2xl shadow bg-white relative z-10">
                        Historique
                    </div>
                    <div className="pl-4 pt-4 flex-grow overflow-y-scroll flex flex-col">
                        {scans &&
                            MONTHS.map(
                                month =>
                                    scans.get(month) && (
                                        <button
                                            key={month}
                                            className="border-b pr-4 py-2 flex items-center gap-4 text-left"
                                            onClick={() => {
                                                setFocus({
                                                    year: 2020,
                                                    month: month,
                                                    scans: scans.get(month),
                                                })
                                                setShowMonth(true)
                                            }}
                                        >
                                            <div
                                                className="text-2xl font-bold text-white h-16 w-16 rounded-md overflow-hidden flex items-center justify-center shadow-md"
                                                style={{
                                                    background: `linear-gradient(45deg, ${gradients[month].start}, ${gradients[month].end})`,
                                                    textShadow:
                                                        "rgba(0,0,0,0.2) 0px 2px 5px",
                                                }}
                                            >
                                                {month.slice(0, 3)}.
                                            </div>
                                            <div className="flex flex-col flex-grow">
                                                <div className="text-md">{month}</div>
                                                <div className="flex items-center gap-2">
                                                    <div className="flex items-center justify-center bg-gray-200 h-6 w-6 rounded text-sm font-bold">
                                                        {scans.get(month)?.length}
                                                    </div>
                                                    <div className="text-xs text-gray-500">
                                                        déchets
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <FiChevronRight className="text-gray-500" />
                                            </div>
                                        </button>
                                    ),
                            )}
                        {!scans && (
                            <div className="flex justify-center">
                                <ReactLoading
                                    type={"spin"}
                                    color={"red"}
                                    height={100}
                                    width={100}
                                />
                            </div>
                        )}
                        {scans && Array.from(scans?.keys()!).length === 0 && (
                            <div className="flex justify-center">
                                Aucun scan de déchet enregistré
                            </div>
                        )}
                    </div>
                </a.div>
                <a.div
                    className="flex flex-col h-full absolute w-full bg-gray-100 shadow-xl z-20"
                    style={monthSpring}
                >
                    {focus && (
                        <HistoryMonth
                            scans={focus.scans}
                            month={focus.month}
                            year={focus.year}
                            onClose={() => setShowMonth(false)}
                        />
                    )}
                </a.div>
            </div>
        </div>
    )
}

export default History
